module.exports = [{
      plugin: require('../../../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Grassroots Salon & Spa","short_name":"Grassroots Salon & Spa","start_url":"/","background_color":"#E4DBD0","theme_color":"#E4DBD0","display":"standalone","icon":"./src/favicon.png","icons":[{"src":"./src/favicon.png","sizes":"512x512","type":"image/png","purpose":"any maskable"}],"legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"f5a6b9042fc4cc91a6ca7b308de6d98d"},
    },{
      plugin: require('../../../node_modules/gatsby-plugin-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../../../node_modules/gatsby-plugin-canonical-urls/gatsby-browser.js'),
      options: {"plugins":[],"siteUrl":"https://www.grassrootssalonandspa.com","stripQueryString":true},
    },{
      plugin: require('../../../node_modules/@bluefin/gatsby-plugin-fisherman-page-creator/gatsby-browser.js'),
      options: {"plugins":[],"queryName":"ServiceCategory","templateName":"ServiceCategory","recipe":"cutilier","tagName":"ServiceCategories","fishermanParentPageType":"Services","orderPath":"order","groupPath":"schedule.name","groupOrderPath":"schedule.order","layoutComponent":"/app/workspaces/v_1/website_envs/merchants_1496/src/components/WebsiteLayout.jsx","instanceId":"gatsby-plugin-fisherman-page-creator_1"},
    },{
      plugin: require('../../../node_modules/@bluefin/gatsby-plugin-fisherman-page-creator/gatsby-browser.js'),
      options: {"plugins":[],"queryName":"ServiceItem","templateName":"ServiceItem","recipe":"cutilier","tagName":"ServiceItems","fishermanParentPageType":"Services","orderPath":"order","layoutComponent":"/app/workspaces/v_1/website_envs/merchants_1496/src/components/WebsiteLayout.jsx","templateProps":{"relatedServiceItemsText":"Other Service Items"},"instanceId":"gatsby-plugin-fisherman-page-creator_2"},
    },{
      plugin: require('../../../node_modules/@bluefin/gatsby-plugin-fisherman-page-creator/gatsby-browser.js'),
      options: {"plugins":[],"queryName":"TeamMember","templateName":"TeamMember","recipe":"cutilier","fishermanParentPageType":"Team","layoutComponent":"/app/workspaces/v_1/website_envs/merchants_1496/src/components/WebsiteLayout.jsx","templateProps":{"otherTeamMembersText":"Other Team Members"},"instanceId":"gatsby-plugin-fisherman-page-creator_3"},
    },{
      plugin: require('../../../node_modules/@bluefin/gatsby-plugin-fisherman-locations/gatsby-browser.js'),
      options: {"plugins":[],"useStorage":true},
    },{
      plugin: require('../../../node_modules/@bluefin/gatsby-plugin-fisherman-animations/gatsby-browser.js'),
      options: {"plugins":[],"enabled":true},
    },{
      plugin: require('../../../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-N67X3P5L","includeInDevelopment":false,"defaultDataLayer":null,"routeChangeEventName":"gatsby-route-change","enableWebVitalsTracking":false,"selfHostedOrigin":"https://www.googletagmanager.com"},
    }]
